export default [
  {
    label: 'Home',
    path: '/',
  },
  {
    label: 'About Me',
    path: '/aboutme',
  },
  {
    label: 'Experience',
    path: '/experience',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  
]
